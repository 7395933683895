.testimonials {
    padding: 60px 20px;
    padding-top:0 ;
    text-align: center;
    background-color: #f9f9f9;
    background: linear-gradient(180deg,#ffff, #f7d073, #ffffff);
  }
  .testimonials .head{
    display: flex;
    gap:10px;
    justify-content: center;
    align-items: center;
  }
  .testimonials .head img{
    width: 100px;
    height: 30px;
  }
  
  .testimonials h2 {
    font-size: 36px;

    background: -webkit-linear-gradient(-257deg, #391c04 18%, #fcc74fe3 52%, rgb(69 47 4 / 87%) 41%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }
  
  .testimonials-grid p {
    font-size: 14px;
    color: #ddd;
    margin-bottom: 5px;
  }
  
  .view-all {
    color: #ff6f61;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 40px;
    display: inline-block;
  }
  
  .testimonials-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .testimonial-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    width: 23%;
    height: 500px;
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
  
  .testimonial-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .testimonial-content {
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
    color: white;
    background-color: rgb(0,0,0,0.5);
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    height: 220px;
  }
  
  .testimonial-content h3 {
    font-size: 22px;
    margin-bottom: 0px;
    color: white;
  }
  
  .location {
    font-size: 16px;
    color: #333;
 
  }
  
  .rating {
    color: #ff6f61;
    
  }
  
  .testimonial {
    font-size: 10px;
  }
  
  @media (max-width: 1024px) {
    .testimonial-card {
      width: 45%;
    }
  }
  
  @media (max-width: 768px) {
    .testimonial-card {
      width: 100%;
    }
  }
  .testp{
    color: #333;
  }
  