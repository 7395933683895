.Subscription {
    text-align: center;
    padding-top: 50px;
    display: flex;
    justify-content: center;
  }
  
  .Subscription-header {
    background-color: #fffcfc;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  .Subscription .para {   
    position: relative;
    width: fit-content;
    padding: 10px;
    margin: 0 auto; /* Center the button */
    font-size: 16px; /* Adjust as needed */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); /* Box shadow */
    transform: skewX(-16deg);
    z-index: 1;
    overflow: hidden;
    /* clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);  */
  }
  
  .Subscription .para::before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    background:linear-gradient(  #9bb4f6, #5c84f3, #f67f74, #ff6f61);/* Border color */
    animation: rotate 5s linear infinite; /* Animation for rotating border */
    z-index: -1;
    
  }
  .Subscription .para::after {   
   content:"";
   position: absolute;
   z-index: -1;
    background-color: #fff;
    inset:3px;
    
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
 

  .Subscription-header .later{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  
  .Subscription-header span{
    vertical-align: middle;
  }
  
  .pricing-plans {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .pricing-plan {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    width: 323px;
    margin: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: grid;
    grid-template-rows: 10% 14% 10% 57% 10%;
  }
    .pricing-plan:hover{
      transform: translateY(-10px);
      box-shadow: 0 10px 20px rgba(224, 122, 122, 0.1);
      border: 2px solid #ff6f61;
    }

  .pricing-plan h3 {
    color: #ff6f61;
    font-size: 23px;
  }
  
  .pricing-plan h2 {
    margin: 16px 0;
    color: #333;
  }
  
  .pricing-plan p {
    margin: 8px 0;
    color: #777;
  }
  
  .pricing-plan ul {
    list-style: none;
    padding: 0;
    margin: 16px 0;
  }
  
  .pricing-plan ul li {
    margin: 8px 0;
  }
  
  .pricing-plan button {
    background-color: #ff6f61;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .pricing-plan button:hover {
    background-color: #e55a4e;
  }
 


  .Subscription {
    position: relative;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:10;
  }
  .contacts{
    color: red;
    font-weight: bold;

    span{
      /* color: #5c84f3; */
    }

  }
  .popup-content {
    background: white;
     width:50%;
     font-size: 18px; 
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    
  }
  .xmark-popup{
    float:right;

  }
  .popup-top{
    background-color: #777;
    background-color: #777;
    padding: 10px;
    color: white;
    border: solid 1px;
    padding: 10px;

  }
  .popup-top h1{font-size: 18px; text-align: center;}
  .order-summary{
    padding: 20px;
  }
  .order-summary ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
  }
  .order-summary ul li {
    display: flex;
    justify-content: space-between;
  }
  .order-summary ul li span{
    align-self: center;
  }
  .order-summary ul  hr{
    color: #777;
    width: 100%;
  }
.Congrats-text{
  color: green;
}
  .popper{
    color: rgb(208, 201, 231);
  }
  .order-summary button {
    background-color: rgb(0, 123, 255);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    transition: background-image 1s ease;
    font-weight: bold;
  }
  .order-summary button:hover{
    
    background-image: linear-gradient(45deg,rgb(3, 76, 150),  rgb(27, 137, 253), rgb(146, 196, 246), rgb(125, 185, 249), rgb(3, 76, 150));

   
  }
  @media (max-width:925px)and (min-width:768px) {
    .Subscription {
      position: relative;
    top:80px;}
  }
