:root{
    --width-img:500px
}

.user-detail {
    display: flex;
    padding: 0 6%;
    padding-top: 80px;
    justify-content: center;
    gap: 37px;
    /* gap: 30px; */
    /* height: 100vh; */
    width: 100%;
    align-items: center;
}

.userImage {
    width: 500px;
    height: 600px;
    border-radius: 20px;
    /* z-index: -1; */
    cursor: pointer;


    .image-scroll-container {
        width: 100%;
    }

    .images {
        width: 100%;
        height: 100%;

        img {
            width: var(--width-img);
            height: 600px;
            border-radius: 20px;
            filter: contrast(150%) brightness(100%) saturate(125%);

        }
    }
}

.userDetails {
    width: 700px;
    display: flex;
    flex-direction: column;


    .name {
        text-align: center;
    }

    .basic-information {
        /* display: grid;
        grid-template-columns: repeat(2, 1fr); */

    }

    .conatctinfo {
        /* display: grid;
        grid-template-columns: repeat(2, 1fr); */
    }

}

.userDetails>* {
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.userDetails strong {
    color: #4287f5;
    font-weight: bold;
    font-size: 17px;
}

.awssld {
    height: 100% !important;
}

.awssld__content {
    border-radius: 30px;
}

.awssld__container {
    height: 100% !important;
}


@media (max-width:650px) {
    .user-detail {
        flex-wrap: wrap;
    }
    .userImage{
        width:332px;
    }
    :root{
        --width-img:332px;
    }
}

@media (max-width:400px) {

    .basic-information,
    .conatctinfo {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

.slick-track{
    display: flex;
}
.error-message{
    color: red;
}
.reveal-btn{

    padding: 10px;
    border-radius: 24px;
    width:fit-content;
  }