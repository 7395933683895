.how-it-works {
 
    text-align: center;
  
  }
  
  .how-it-works .head {
    display: flex;
    gap:20px;
    justify-content: center;
    align-items: center;
    padding: 17px;
    background-image: linear-gradient(90deg, #f6f0dd 2.38%, hsla(46, 58%, 92%, 0) 23.13%, hsla(46, 58%, 92%, 0) 72.32%, #f6f0dd 97.78%), linear-gradient(143deg, #9e7c3d 21.68%, #d0ac6c 78.32%);
 margin-bottom: 40px;
    }
  .how-it-works .head h2{
  
    font-size: 27px;
    margin-bottom: 20px;

    color: rgb(255, 255, 255);
 
  }
  .how-it-works .head img{
   
    height: 5rem;
  }
  
  .how-it-works .steps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .how-it-works .step {
    width: 30%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #3536351f;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .how-it-works .step:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 20px 40px  #e4c082;
    border: 1px solid #fad89e;
  }
  
  .how-it-works .step h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #6a818a;
   text-decoration: none;
    color: #a400006b;
  }
   
  .how-it-works .step a {
    
   text-decoration: none;

  }
  .how-it-works .step p {
    font-size: 16px;
    color: #666;
  }
  
  .how-it-works .step i {
    font-size: 40px;
    color: rgb(49 196 190 / 75%);
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .how-it-works .step {
      width: 100%;
    }
  }
  