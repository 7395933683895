.fixed-buttons {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
  z-index: 9;
}

.whatsapp-button, .quote-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  width: 300px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
}

.whatsapp-button {
  background-color: #25D366; /* WhatsApp green */
}

.quote-button {
  background-color: #FF6F61; /* Matching your theme's pink */
}

.whatsapp-button img {
  margin-right: 10px;
  width: 40px;
  height: 35px;
}



.whats{
  color: white;
}
*{
  scroll-behavior:smooth;
}
@media (max-width:500px) {
  .fixed-buttons{
    display: flex;
    flex-direction: column;
  }
  .whatsapp-button, .quote-button {
  
    font-size: 20px;
  }
  
}


.enhanced-image {

  filter: 
    brightness(1.2) 
    contrast(1.3) 
    saturate(1.2);
  transition: all 0.3s ease;
}

.enhanced-image:hover {
  filter: 
    brightness(1.3) 
    contrast(1.4) 
    saturate(1.3);
}



.line-shade {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: rgb(0 107 179);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.line-shade:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.line-shade .icon {
  transform: translate(4px);
}

.line-shade:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.line-shade::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}
.membership-status{
  background:#ea4e4e;
    color: white;
    font-size: 26px;
    padding: 4px;
    border-radius: 50%;
}
.Gold{
  background: #FFB800;
}
.Platinum{
  background: #c95beb;
}
