

.user-details{
  display: flex;
 gap: 20px;
  flex-wrap: wrap; 
  justify-content: center;
}
.each-profile{
  width: 32%;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  gap: 16px;
  padding: 19px;
  transition: transform .3s ease, box-shadow .3s ease;
  width: 45%;
  display: grid;
  grid-template-columns: 50% 50%;
  
}
.each-profile h4{
  color: #07736b;
}
@media screen and (max-width:1024px) {
.each-profile{
  width: 45%;}
}

.each-profile:hover:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.user-details div img{
  border-radius: 104px;
  width: 203px;
  object-fit: cover;
  height: 300px;
  background-position: center;
}
@media (max-width:768px) {
  .user-details div{
      width: 100%;
  }
} 

.pagination {
margin-top: 20px; 
display: flex;
justify-content: flex-end;
margin-right: 10px; 
}

.pagination button {
margin: 0 5px;
padding: 5px 10px;
cursor: pointer;
}

.pagination button.active {
font-weight: bold;
text-decoration: underline;
}


  
.profile-info {
cursor: pointer;
}

