
.sign-up-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    height: 100vh;
    position: absolute;
   
    right: 17%;
    /* position: relative;
    top:70px;
    margin-bottom: 80px; */
}
.sign-up-page-other{
    background: url('../images/elegant-adult-couple.jpg') no-repeat center center;

    background-image: url("../images//background-img.jpg");
    position: relative;
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    top: 70px;
    right: 0;
    left: 0;
}

.sign-up-container {
    background: rgba(255, 255, 255, 0.9);
    padding: 39px;
    border-radius: 10px;
    text-align: center;
    width: 413px;
}

.sign-up-container h2 {
    color: #a20707;
    margin-bottom: 20px;
}

.sign-up-container h2 span{
    color: #4e3131;
}
.sign-up-container from {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sign-up-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100% !important;

}
.react-tel-input {
  
    width: 100% !important;;

}
.success-message{
    color: #28a745!important;
}
.sign-up-container p{
    color: red;
    margin-top: 20px;
}
.sign-up-button {
    width: 80%;
    padding: 10px;
    background: #28a745;
    background: linear-gradient(to right, #6cb2f4, #30ddcf);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-weight: 800;
}
.addictional-text{
    font-size: 14px;
    color: grey !important;

    a{
        color: #1978d0;
        text-decoration: underline;
        font-weight: 500;
    }
}

.sign-in-section {
    margin-top: 20px;
}

.sign-in-button {
    display: inline-block;
    padding: 10px 20px;
    background: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}
.signin-text {
    color: black !important;
    font-size: 14px;

    a{
        font-size: 15px;
        font-weight: 500;
    }
}
.signin-text a{
    color: #1978d0;
    text-decoration: none;
}
.signin-text a:hover{
    color: #4ce3fd;
  
}
.Email-input button{
    width: auto; 
    font-size: 13px;
        padding: 0px;
        height: 43px;
        vertical-align: middle;
        margin-top: 7px;}
.Email-input {
            display: flex;
            gap:10px;
        }
.sign-up-verify{
    background-color: #2c9fec;
    color: #fff;
    border: none;
    padding: 0px 13px;
    border-radius: 4px;
    font-size: 11px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
}
.sign-up-verify +span{
    font-size: 11px;
}
.verifed-text{
    color: green !important;
}
.verifed-text i{
    background: green;
    color: white;
    padding: 3px;
}

@media (max-width:925px)and (min-width:768px) {.sign-up-page-other{ top: 100px;}}

.google-signup .n1UuX-DkfjY{
    display: none !important;
}
