.add-upload-section{
    position: relative;
    top:70px;
    display: flex;
    justify-content: center;
    margin:30px auto;
    margin-bottom: 100px;
    padding: 20px;
}
.upload-img{
    background: -webkit-linear-gradient(0deg, rgb(223 114 114) 0%, rgb(49 196 190 / 75%) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.images-list{
    display: flex;
    flex-wrap: wrap;
    padding: 60px 30px;
    gap:20px;
    background: linear-gradient(rgb(201, 223, 223),rgb(181, 245, 245),rgb(158, 201, 232)
    );
    justify-content: center;
    align-items: center;
}
.list-images{
    width:250px;
    height:300px;
}
.add-img{
    width:200px;
    height:300px;
    background: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.plus-icon{
    font-size: 57px; 
}
